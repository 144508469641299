@import "../../../../scss/variables";

body {
  &.dark-mode {
    .dropbox {
      .multipleIconContainer {
        .multipleIconImg div {
          color: green;
        }
      }
    }
    .swItemManagement {
      .chartCard {
        background-color: #333333;
        .pieChart {
          .noItemImg {
            .noItemImgTitle {
              color: $dark-primary-color;
            }
          }
        }
      }
    }
    .swItemManagement {
      .chartCard {
        .heading {
          color: $dark-primary-text-color;
        }
        .count {
          color: $dark-primary-text-color;
        }
      }
      .smallCards {
        .title {
          color: $dark-primary-text-color;
        }
        .cardColumn {
          background-color: #333333;
        }
        .count {
          color: $dark-primary-color;
        }
      }
    }
  }
}

.swItemManagement {
  .title {
    font-size: $font-size-24;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
  }
  .chartCard {
    background: $white-color;
    border-radius: 15px;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1);
    min-height: 330px;
    width: 100%;
    padding: 10px;
    height: 100%;
    .heading {
      font-size: $font-size-16;
      color: $text-black-color;
      font-weight: 500;
      font-family: $font-lato;
      margin-bottom: 0px;
    }
    .count {
      font-size: $font-size-16;
      color: $text-black-color;
      font-weight: 700;
      font-family: $font-lato;
    }
    .pieChart {
      position: relative;
      .noItemImg {
        position: absolute;
        top: 0;
        width: 100%;
        .noItemImgTitle {
          font-size: $font-size-16;
          color: $cerulean-color;
          font-weight: 700;
          font-family: $font-lato;
          text-align: center;
        }
        img {
          width: 100%;
          height: 170px;
        }
      }
    }
  }
  .smallCards {
    padding: 0px 20px;
    .topSmallCards,
    .bottomSmallCards {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    .cardColumn {
      background: $white-color;
      border-radius: 15px;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1);
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 10px;
      min-width: 140px;
      width: 100%;
      max-width: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.active {
        border: 1px solid $cerulean-color;
      }
      .title {
        color: $shadow-grey;
        font-size: $font-size-14;
        font-weight: 600;
        font-family: $font-lato;
        text-align: center;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin-right: 3px;
          margin-top: 2px;
        }
      }
      .count {
        color: $cerulean-color;
        font-size: $font-size-64;
        font-weight: 600;
        font-family: $font-lato;
        text-align: center;
      }
    }
  }
}

.commentCloseWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  .commentClose {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;
    cursor: pointer;
    font-size: $font-size-14;
    font-weight: 600;
    font-family: $font-lato;

    background-color: $cerulean-color;
    border-radius: 71px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    height: 44px;
    span {
      margin-right: 5px;
    }
    &.active {
      color: white;
    }
    &[disbled="disbled"] {
      cursor: not-allowed;
      pointer-events: none;
      color: $gainsboro;
    }
  }
}
.bothFormBox {
  margin-top: 30px;
  .commintingBox,
  .closeItemBox {
    border: 1px solid $gainsboro;
    padding: 10px 20px;
    .buttonEnd {
      justify-content: flex-end;
    }
  }
}

.allCommentWrapper {
  margin-top: 20px;
  .allCommentList {
    border: 1px solid #dfdfdf;
    padding: 10px 20px 0px 20px;
    margin-bottom: 20px;
    .detail {
      font-size: $font-size-14;
      font-weight: 400;
      margin-bottom: 10px;
      color: $text-black-color;
      display: flex;
      .closedImg {
        img {
          max-width: 150px;
          max-height: 120px;
          object-fit: cover;
        }
      }
      span {
        font-size: $font-size-14;
        font-weight: 700;
        margin-right: 10px;
        color: $text-black-color;
      }
    }
  }
}

.closedItemDropbox {
  position: relative;
  .overideFormDropBox {
    .dropbox {
      border: none;
      height: auto;
      width: auto;
      justify-content: flex-end;
      .multipleIconContainer {
        display: flex;
        margin-top: -70px;
        margin-right: 10px;
      }
    }
  }
}

.grid_main_body {
  .openItemSw {
    .overideSearchAuto {
      .MuiAutocomplete-hasClearIcon {
        .formControlRoot {
          .MuiAutocomplete-input {
            padding-left: 60px;
          }
        }
      }
      .formControlRoot {
        .MuiAutocomplete-endAdornment {
          left: 9px;
        }
        .MuiAutocomplete-input {
          padding-left: 40px;
        }
      }
    }
  }
}
