@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideTextArea {
      .textArea {
        background-color: $dark-form-all-input-color;
      }
    }
    .jodit-container {
      .jodit-toolbar__box {
        background-color: $dark-tertiary-background-color;
      }

      .jodit-wysiwyg {
        background-color: $dark-form-all-input-color;
      }
      .jodit-wysiwyg p {
        color: $dark-primary-text-color;
      }
    }
    .jodit_theme_summer {
      --jd-color-background-default: #417505;
      --jd-color-border: #474025;
      --jd-color-panel: #5fd3a2;
      --jd-color-icon: #8b572a;
    }
  }

  .overideTextArea {
    .charLimitText {
      margin-top: 5px;
      font-size: 12px;
      color: #ff5122;
    }
    .textArea {
      width: 100%;
      height: 100px;
      max-height: 102px;
      padding: 12px 20px;
      box-sizing: border-box;
      border: 1px solid $fossil-grey;
      border-radius: 15px;
      background-color: $white-color;
      font-size: $font-size-16;
      resize: none;
      width: 100%;
      resize: none;
      font-family: $font-lato;
      overflow-y: auto !important;
      &:focus-visible {
        border: 1px solid $fossil-grey;
        outline: none;
      }
      &::placeholder {
        color: #9a9fa8;
      }
    }
    .errorText {
      color: $red-color;
      margin-left: 0px;
      font-size: 0.75rem;
      margin-top: 4px;
    }
  }
}
