@import "../../../../scss/variables";

.overRideViewProfile {
  background: #ffffff;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100%;
  max-width: 962px;

  .header {
    display: flex;
    flex-direction: row;
    max-width: 962px;
    height: 120px;
    width: 100%;
    .profileImg {
      .borderProfileImg {
        border: 4px solid #1b436e;
        border-radius: 50%;
        width: 172px;
        height: 172px;
        margin-top: -59px;
        margin-left: 20px;
      }
    }
    .name {
      display: flex;
      flex-direction: column;
      margin-left: 18px;
      .userName {
        font-family: "Lato";
        font-weight: 700;
        font-size: 28px;
        color: #333333;
        margin-top: 10px;
      }
      .userJob {
        font-family: "Lato";
        font-weight: 400;
        font-size: 24px;
        color: #666666;
      }
    }
    .btnProfile {
      display: flex;
      flex-direction: row;
      width: 143px;
      height: 44px;
      background: #1b436e;
      border-radius: 25px;
      margin-top: 18px;
      margin-left: 43px;
      padding: 8px;
      cursor: pointer;
      .editImage {
        margin-left: 12px;
      }
      .editProfileBtn {
        font-family: "Lato";
        font-weight: 700;
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-left: 5px;
      }
    }
  }
  .profileDetails {
    display: flex;
    flex-direction: row;
    width: 100%;
    .leftSection {
      border-right: 1px solid #d9d9d9;
      display: flex;
      flex-direction: column;
      width: 55%;
      padding: 30px;
      .PageHeading {
        font-family: "Lato";
        font-weight: 700;
        font-size: 20px;
        color: #333333;
      }
      .leftSection1 {
        display: flex;

        width: 100%;
        .columnLeftSide {
          display: flex;
          flex-direction: column;
          width: 50%;

          .heading {
            font-family: "Lato";
            font-weight: 400;
            font-size: 16px;
            color: #666666;
          }
          .response {
            font-family: "Lato";
            font-weight: 600;
            font-size: 16px;
            color: #333333;
            margin-top: -22px;
          }
          .email {
            font-family: "Lato";
            font-weight: 600;
            font-size: 16px;
            color: #1b436e;
            margin-top: -22px;
          }
          .userRoleResponse {
            font-family: "Lato";
            font-weight: 600;
            font-size: 16px;
            color: #333333;
          }
        }
        .columnRightSide {
          display: flex;
          flex-direction: column;

          width: 65%;
          margin-left: 74px;
          .heading {
            font-family: "Lato";
            font-weight: 400;
            font-size: 16px;
            color: #666666;
          }
          .response {
            font-family: "Lato";
            font-weight: 600;
            font-size: 16px;
            color: #333333;
            margin-top: -22px;
          }
          .email {
            font-family: "Lato";
            font-weight: 600;
            font-size: 16px;
            color: #1b436e;
            margin-top: -22px;
          }
        }
      }
    }
    .rightSection {
      display: flex;
      flex-direction: column;
      width: 45%;
      padding: 20px;
      padding-left: 90px;
      .signature {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .signatureHeading {
          font-family: "Lato";
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          // display: flex;
          // flex-direction: column;
        }
        .borderProfileImg {
          width: 100px;
          height: 100px;
          align-items: center;
        }
        .response {
          font-family: "Lato";
          font-weight: 600;
          font-size: 16px;
          color: #1b436e;
          margin-top: 5px;
          margin-left: -37px;
        }
      }
      .PageHeading {
        font-family: "Lato";
        font-weight: 700;
        font-size: 20px;
        color: #333333;
      }
      .heading {
        font-family: "Lato";
        font-weight: 400;
        font-size: 16px;
        color: #666666;
      }
      .response {
        font-family: "Lato";
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        margin-top: -22px;
      }
    }
  }
}

body {
  &.dark-mode {
    .overRideViewProfile {
      background: $dark-primary-background-color;
      border: 1px solid $dark-primary-text-color;
      .header {
        .profileImg {
          .borderProfileImg {
            border: 4px solid $dark-primary-color;
          }
        }
        .name {
          .userName {
            color: $dark-primary-text-color;
          }
          .userJob {
            color: $dark-primary-text-color;
          }
        }
        .btnProfile {
          background: $dark-primary-color;

          .editProfileBtn {
            color: #ffffff;
          }
        }
      }
      .profileDetails {
        .leftSection {
          border-right: 1px solid #d9d9d9;
          .PageHeading {
            color: $dark-primary-text-color;
          }
          .leftSection1 {
            .columnLeftSide {
              .heading {
                color: $dark-primary-text-color;
              }
              .response {
                color: $dark-primary-text-color;
              }
              .email {
                color: $dark-primary-color;
              }
              .userRoleResponse {
                color: $dark-primary-text-color;
              }
            }
            .columnRightSide {
              .heading {
                color: $dark-primary-text-color;
              }
              .response {
                color: $dark-primary-text-color;
              }
              .email {
                color: $dark-primary-color;
              }
            }
          }
        }
        .rightSection {
          .signature {
            .signatureHeading {
              color: $dark-primary-text-color;
            }
            .response {
              color: $dark-primary-color;
            }
          }
          .PageHeading {
            color: $dark-primary-text-color;
          }
          .heading {
            color: $dark-primary-text-color;
          }
          .response {
            color: $dark-primary-text-color;
          }
        }
      }
    }
  }
}
