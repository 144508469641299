@import "../../../../scss/variables";

.lmssavecancelbtn {
  display: flex;
  justify-content: flex-end;
}

body {
  &.dark-mode {
    .custom-data-grid .MuiDataGrid-columnHeaders {
      background: $black-color;
      color: $white-color;
    }
  }
}
