@import "../../../../scss/variables";

body {
  &.dark-mode {
    .overideToolbox {
      .title {
        color: $dark-primary-text-color;
      }
    }
  }
}

.overideToolbox {
  .addtoolBoxContainer {
    max-width: 700px;
  }
  .addtoolboxButton {
    justify-content: flex-end;
  }
}
