@import "../../../scss/variables";

body {
  &.dark-mode {
    .commonOveride {
      .commoninfoWrap {
        background-color: $dark-secondary-background-color;
        .commoninfoBox {
          .commoninfoleft {
            .title {
              color: $dark-primary-color;
            }
            .listView {
              color: $dark-primary-text-color;
            }
            .blockView {
              color: $dark-primary-text-color;
            }
            .columns {
              span {
                color: $dark-primary-text-color;
              }
              .columnsf {
                color: $dark-primary-text-color;
              }
            }
          }
        }
      }
    }
  }
}

.commonOveride {
  margin-top: 40px;
  .titleDetail {
    font-family: $font-lato;
    font-size: $font-size-24;
    color: $text-black-color;
    font-weight: 700;
    margin-bottom: 25px;
    text-transform: capitalize;
  }
  .commoninfoWrap {
    background: $white-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 12px 25px 16px 25px;
    .commoninfoBox {
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
        flex-wrap: wrap;
      }
      .commoninfoleft {
        &.widthIncr {
          width: 78%;
        }
        width: 70%;
        text-align: left;
        @media (max-width: 767px) {
          width: 100%;
        }
        .title {
          font-size: $font-size-20;
          color: $cerulean-color;
          font-weight: 700;
          font-family: $font-lato;
          margin-bottom: 23px;
        }
        .subtitle {
          font-size: $font-size-16;
          color: $cerulean-color;
          font-weight: 700;
          font-family: $font-lato;
          margin-bottom: 8px;
        }
        .listView {
          font-family: $font-lato;
          font-weight: 700;
          font-size: $font-size-16;
          line-height: 19px;
          color: $text-black-color;
          border-bottom: 1px solid $chinese-white;
          margin-bottom: 13px;
          padding-bottom: 10px;
          .blockView {
            display: block;
            width: 100%;
            margin-top: 12px;
          }
          &.desNone {
            margin-bottom: 0px;
            padding-bottom: 0px;
            border: 0px;
            &.borderAdd {
              border-bottom: 1px solid $chinese-white;
              margin-bottom: 13px;
              padding-bottom: 10px;
            }
          }
          span {
            text-transform: capitalize;
            padding-left: 4px;
            font-family: $font-lato;
            font-weight: 400;
            font-size: $font-size-16;
            line-height: 19px;
            color: $text-black-color;
            &.focusedText {
              font-family: $font-lato;
              font-weight: 700;
              font-size: $font-size-16;
              line-height: 19px;
              color: $cerulean-color;
            }
          }
          .breakValue {
            display: flex;
            flex-wrap: wrap;
            width: 70%;
            span {
              font-weight: 400;
            }
          }
        }
        .columns {
          &.noWrapText {
            .listView {
              width: 100%;
              display: block;
            }
          }
          display: flex;
          width: 100%;
          .font-w400 {
            font-weight: 400;
          }
          .columnsf {
            width: 60%;
          }
          .columnsl {
            width: 40%;
            span {
              text-transform: capitalize;
              padding-left: 4px;
              font-family: $font-lato;
              font-weight: 400;
              font-size: $font-size-16;
              line-height: 19px;
              color: $text-black-color;
            }
            &.yesbtn {
              background-color: $green-color;
              color: $white-color;
              width: 120px;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &.nobtn {
              background-color: $nobg;
              color: $white-color;
              width: 120px;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .mb-2 {
              margin-bottom: 10px;
            }
            .yesbtn {
              background-color: $green-color;
              color: $white-color;
              width: 120px;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .nobtn {
              background-color: $nobg;
              color: $white-color;
              width: 120px;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          span {
            text-transform: capitalize;
            padding-left: 4px;
            font-family: $font-lato;
            font-weight: 700;
            font-size: $font-size-16;
            line-height: 19px;
            color: $text-black-color;
            cursor: default;
            &.focusedText {
              font-family: $font-lato;
              font-weight: 700;
              font-size: $font-size-16;
              line-height: 19px;
              color: $cerulean-color;
            }
          }
          .listView {
            width: 50%;
            @media (max-width: 767px) {
              width: 100%;
            }

            &.desNone {
              margin-bottom: 0px;
              padding-bottom: 0px;
              border: 0px;
            }
          }
        }
      }
      .commoninfoRight {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 767px) {
          width: 100%;
        }
        .commonInformationImgBox {
          border: 1px solid #999999;
          box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          width: 154px;
          height: 154px;
          .commonInformationImg {
            img {
              width: 131px;
              height: 122px;
            }
          }
          .basiimgname {
            text-align: center;
            font-size: $font-size-14;
            color: $shadow-grey;
            font-weight: 700;
            font-family: $font-lato;
          }
        }
        .commonSignatureBox {
          .title {
            font-size: $font-size-16;
            color: $text-black-color;
            font-weight: 700;
            font-family: $font-lato;
            margin-bottom: 10px;
          }
          .box {
            padding: 15px;
            border: 1px solid $shadow-grey;
            border-radius: 15px;
          }
        }
      }
    }
  }
}
.additionalListBox {
  .additionalList {
    margin-top: 40px;
    &:first-child {
      margin-top: 0px;
    }
  }
}
