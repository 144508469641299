@import "../../../../src/scss/variables";
.Back_btn_style {
  color: #1b436e;
}
.grid_lookup_cred_sec {
  text-align: left;
  .grid_main_header {
    margin-bottom: 15px;
    .grid_heading_style {
      font-family: $font-lato;
      font-weight: 700;
      font-size: $font-size-22;
      line-height: 29px;
    }
  }
  .two_grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .grid_main_body {
    padding: 0px;
    margin: 0px;
  }
}
.grid_lookup_cred_sec:first-child {
  margin-top: 0px;
}

.div-cred-filter-container {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: end;
  justify-content: space-between;
  .div-filter-left {
    width: 80%;
  }
  .div-filter-right-download {
    cursor: pointer;
    background-color: #34a853;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    // margin-bottom: 21px;
    border-radius: 41px;
    gap: 12px;
    padding-left: 20px;
    padding-right: 20px;
    height: 45px;
    white-space: nowrap;
    text-align: left;
  }
}
