@import "../../../../../scss/variables";

.northCarolinaOverride {
  .twoQuestionBox {
    .twoQuestionList {
      margin-bottom: 20px;
      .twoQuestionTitle {
        color: $shadow-grey;
        font-size: $font-size-16;
        font-weight: 600;
        font-family: $font-lato;
      }
    }
  }

  .commonTableBoxNorth {
    width: 100%;
    margin-bottom: 20px;
    .commonTableBoxNorthTitle {
      font-size: $font-size-16;
      font-weight: 700;
      font-family: $font-lato;
      color: $text-black-color;
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    &.table_white {
      background-color: $white-color;
    }
    .title {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: $font-size-16;
      font-weight: 700;
      font-family: $font-lato;
      color: $cerulean-color;
    }
    .tableWrapper {
      overflow: hidden;
      overflow-x: auto;
      display: block;
      width: 100%;

      &::-webkit-scrollbar {
        width: 12px;
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 0px;
      }

      &::-webkit-scrollbar-track {
        background-color: #eee;
      }
    }

    table {
      width: 100%;
      .tableHead {
        background-color: $Bright-Gray;
        th {
          font-size: $font-size-14;
          font-weight: 700;
          font-family: $font-lato;
          color: $text-black-color;
          &.sixty {
            width: 60%;
          }
          &.fourty {
            width: 40%;
          }
          .smallText {
            font-size: $font-size-14;
            font-weight: 500;
            font-family: $font-lato;
            color: $text-black-color;
          }
        }
      }
      .tableBody {
        .overideSelectMenu {
          .selecticonCls {
            right: -4px;
            width: 40px;
            top: -12px;
          }
          .selectBox {
            font-size: $font-size-12;
            padding: 0px;
            border-radius: 0px;
            height: 27px;
            min-width: 110px;
            width: 100%;
            .MuiSelect-select {
              padding: 10px;
            }
          }
        }
        .overideDatePicker {
          [data-testid="CalendarIcon"] {
            background-size: 60%;
          }
          .formControlRoot {
            .MuiInputBase-root {
              .MuiOutlinedInput-notchedOutline {
                border-radius: 0px;
                padding: 0px;
                height: 30px;
                top: -8px;
              }
              .MuiInputBase-input {
                height: 17px;
                padding: 2px;
                font-size: $font-size-12;
              }
            }
          }
          .overideDatePicker {
            font-size: $font-size-12;
          }
        }
        .overideInputField {
          .inputRoot {
            padding: 4px 8px;
            font-size: $font-size-12;
            border-radius: 0px;
            input {
              height: 17px;
              padding: 0px;
            }
          }
          .formControlRoot {
            font-size: 12px;
            border-radius: 0px;
          }
        }
        .itemFondText {
          display: flex;

          span {
            font-size: $font-size-16;
            font-weight: 400;
            font-family: $font-lato;
            color: $text-black-color;
            div[style] {
              width: auto !important;
              position: relative !important;
            }
            &:first-child {
              font-weight: 500;
            }
            &:last-child {
              margin-left: 5px;
            }
          }
        }
        td {
          font-size: $font-size-14;
          font-weight: 400;
          font-family: $font-lato;
          color: $text-black-color;
          vertical-align: middle;
          min-width: 100px;
          .lastGrayText {
            margin: 5px;
            color: $shadow-grey;
            border: 1px solid $shadow-grey;
            padding: 5px 10px;
          }
          &.projectPhaseLeft {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 44px;
            .left {
              width: 85%;
            }
            .right {
              width: 15%;
              .check {
                .tickbox {
                  position: relative;
                  width: 16px;
                }
              }
            }
          }
        }
      }
    }

    table,
    th,
    td {
      border-bottom: 1px solid $chinese-white;
      border-collapse: collapse;
      padding: 5px;
      .statuss {
        div {
          font-size: $font-size-16;
          font-weight: 700;
          font-family: $font-lato;
          &.closed {
            color: $green-color;
          }
          &.open {
            color: $nobg;
          }
        }
      }
    }
  }
}

body {
  &.dark-mode {
    .northCarolinaOverride {
      .commonTableBoxNorth {
        .commonTableBoxNorthTitle {
          color: $dark-primary-text-color;
        }
        table {
          .tableHead {
            th {
              color: $dark-primary-text-color;
              .smallText {
                color: $dark-primary-text-color;
              }
            }
            background-color: $space-grey-color;
          }
          .tableBody {
            .overideSelectMenu {
              .selectBox{
                border: 1px solid $dark-primary-text-color;
              }
            }
            td {
              color: $dark-primary-text-color;
              .overideDatePicker{
                .formControlRoot{
                fieldset{
                  border-color: $dark-primary-text-color;
                }
              }
              }
            }
          }
        }
      }
    }
  }
}
