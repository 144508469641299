.oberrideViewAttendee {
  .attendeesListTable {
    .container {
      border: 0.5px solid #d9d9d9;
    }
    .col {
      border: 0.5px solid #d9d9d9;
    }
  }
  .inline-row {
    display: flex;
    justify-content: flex-start;
    gap: 150px;
    align-items: center;
  }
  .inline-row .grid-item {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    span {
      font-size: 16px;
      color: #333333;
    }
  }
  .comp_signatSection {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: flex-start;
  }
}
@media print {
  .oberrideViewAttendee {
    width: 100%;
    .widthpdf-100 {
      width: 100% !important;
    }
  }
  .oberrideViewAttendee .attendeesListTable .container {
    width: 100%;
  }
  .viewImageBox {
    .viewList.small {
      .listItem {
        width: 500px;
        img {
          width: 500px;
          height: 350px;
        }
      }
    }
  }
}
