@import "../../../scss/variables";

.credntialReporttopHeaderDrillRight {
    display: flex;
    justify-content: right;
    .downloadBox {
      background-color: $green-color;
      max-width: 165px;
      font-size: $font-size-14;
      font-weight: 500;
      font-family: $font-lato;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white-color;
      padding: 10px 20px;
      border-radius: 100px;
      cursor: pointer;
      .downloadIcon {
        margin-right: 10px;
      }
    }
  }