@import "../../scss/variables";

.verifyEmail {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $white-color;
  &__container {
    margin: 20px;
    margin-top: 50px;
    @media screen and (min-width: 768px) {
      display: flex;
      padding-top: 3%;
      margin-top: 0px;
      justify-content: space-between;
    }
  }
  &__logo {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      margin-top: 0px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      margin-top: 0px;
    }
  }
}
