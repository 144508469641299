@import "../../../../scss/variables";
.pdfOveride {
  display: block;
  width: 100%;
}
.pdfInnerOverRide {
  display: block;
  width: 100%;
}
@media print {
  .pdfOveride {
    &.InspectionContainer {
      .hideinprint{
        display: none;
      }
      .confQuestionAns {
        margin-bottom: 5px;
        font-size: $font-size-10;
      }
      margin-top: 0px;
      .title {
        &.repotPdfHeading {
          display: none;
        }
      }
      .clientPdfDetail {
        .clientName,
        .clientLogo {
          display: none;
        }
      }
    }
    .pdfInnerOverRide {
      display: block;
      width: 100%;
      .overReportHeader {
        .reportHeader {
          .downloadpdf {
            &.sw {
              display: none;
            }
          }
          padding: 0px 15px;
          margin-bottom: 0px;
          .reportHeaderLeft {
            display: flex;

            .reportTitle {
              font-size: $font-size-10;
              margin-right: 20px;
              span {
                font-size: $font-size-10;
              }
            }
            .reportTitle.right {
              font-size: $font-size-10;
            }
          }
        }
      }
      .basicOveride {
        .basicinfoWrap {
          padding: 0px 15px;
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
          margin-bottom: 10px;
          .basicinfoBox {
            .basicinfoleft {
              &.sw {
                width: 100%;
                .info-report {
                  display: none;
                }
                .removeInPdf {
                  display: none;
                }
                p {
                  &.listView {
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                    font-size: $font-size-10;
                    line-height: 12px;
                    span {
                      font-size: $font-size-10;
                      line-height: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .categoriesView {
        .categoriesTitle,
        .subCategoriesTitle {
          margin-top: 0px;
          font-size: $font-size-10;
          padding: 0px 15px;
          width: 100%;
        }
        .subCategoriesTitle {
          margin-top: 5px;
        }

        .subCateViewBox {
          .subCateView {
            margin-top: 5px;
            padding: 0px 15px;
            .commonInsOveride {
              .commoninfoWrap {
                .listViewBox {
                  .listDetailBox {
                    .questionDetail {
                      padding: 5px 0px;
                      padding-bottom: 0px;
                      border-bottom: none;
                      display: flex;
                      justify-content: space-between;
                      .questions {
                        width: 85%;
                        span {
                          font-size: $font-size-10;
                          min-height: 14px;
                        }
                      }
                      .questionitem {
                        width: 15%;
                        .commonBtn {
                          height: auto;
                          font-size: $font-size-10;
                          &.notAcceptableBtn {
                            text-align: right;
                            color: $nobg;
                            background-color: $white-color;
                          }
                          &.acceptableBtn {
                            text-align: right;
                            color: $green-color;
                            background-color: $white-color;
                          }
                          &.notApplicableBtn.gray {
                            text-align: right;
                            color: $gray;
                            background-color: $white-color;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .viewReportIns {
              table {
                width: 100%;
                tr {
                  &.tableHead {
                    width: 100%;
                    th {
                      padding: 2px 10px;
                      font-size: $font-size-10;
                      &:nth-child(1) {
                        width: 250px;
                      }
                      &:nth-child(2) {
                        width: 500px;
                      }
                      &:nth-child(3) {
                        width: 150px;
                      }
                      &:nth-child(4) {
                        width: 100px;
                      }
                      &.swinspectiondownloadprint {
                        display: none;
                      }
                    }
                  }
                  &.tableBody {
                    td {
                      padding: 2px 10px;
                      font-size: $font-size-10;
                      &:nth-child(1) {
                        width: 250px;
                      }
                      &:nth-child(2) {
                        width: 500px;
                      }
                      &:nth-child(3) {
                        width: 150px;
                      }
                      &:nth-child(4) {
                        width: 100px;
                      }
                      &.swinspectiondownloadprint {
                        display: none;
                      }
                      .statuss {
                        .open,
                        .closed {
                          font-size: $font-size-10;
                        }
                      }
                      .itemFondText {
                        font-size: $font-size-10;
                        span {
                          font-size: $font-size-10;
                        }
                      }
                    }
                  }
                }
              }

              .title {
                margin-top: 0px;
                font-size: $font-size-10;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      .mendetryText {
        font-size: $font-size-10;
      }
    }
  }
  .reportSignatureImg {
    margin-top: 5px;
    img {
      max-height: 70px;
      height: 100%;
    }
  }
}

.InspectionContainer {
  .confQuestionAns {
    font-family: $font-lato;
    font-weight: 400;
    font-size: $font-size-16;
    color: $black-color;
    strong {
      margin-right: 5px;
      font-weight: 700;
    }
  }
  .notWithThreeQuestion {
    margin-top: 20px;
    .notWithThreeAnswer {
      margin-bottom: 20px;
      font-family: $font-lato;
      font-weight: 400;
      font-size: $font-size-16;
      color: $black-color;
      strong {
        margin-right: 5px;
        font-weight: 700;
      }
    }
  }
}
