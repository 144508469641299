@import "../../../../scss/variables";

body {
  &.dark-mode {
    .commonOveride {
      .titleDetail {
        color: $dark-primary-text-color;
      }
      .commoninfoWrap {
        background: $dark-secondary-background-color;
        .commoninfoBox {
          .commoninfoleft {
            .title {
              color: $dark-primary-color;
            }
            .columns {
              .listView {
                color: $dark-primary-text-color;
              }
              .focusedText {
                color: $dark-primary-text-color;
              }
              .font-w400 {
                color: $dark-primary-text-color;
              }
            }
          }
        }
      }
    }
  }
}

.viewOverideContact {
  .commonOveride {
    margin-top: 0px;
  }
}
.filterbtn:hover {
  cursor: pointer;
}
.filterbtn {
  margin-left: 10px;
}
.contactswitch .MuiFormControlLabel-label {
  margin-left: 15px;
  font-family: $font-lato;
}

.contactswitch .MuiFormLabel-colorPrimary {
  font-family: $font-lato;
}
.addcontactbtn {
  display: flex;
  justify-content: flex-end;
}

.div-filter-container {
  display: flex;
  justify-content: space-between;
  gap: 34%;
  width: 100%;
  .div-download-email-right {
    cursor: pointer;
    background-color: #34a853;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-bottom: 21px;
    border-radius: 41px;
    gap: 12px;
    padding-left: 20px;
    padding-right: 20px;
    height: 45px;
    white-space: nowrap;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .div-filter-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .div-download-email-right {
    width: 100%;
    text-align: center;
  }
}

.div-asset-filter-container {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: end;
  justify-content: space-between;
  .div-filter-left {
    width: 80%;
  }
  .div-filter-right-download {
    cursor: pointer;
    background-color: #34a853;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-bottom: 21px;
    border-radius: 41px;
    gap: 12px;
    padding-left: 20px;
    padding-right: 20px;
    height: 45px;
    white-space: nowrap;
    text-align: left;
  }
}

.div-user-filter-container {
  display: flex;
  gap: 29%;
  width: 100%;
  margin-top: 2%;
  .div-filter-left {
    width: 40%;
  }
  .div-filter-right-download {
    cursor: pointer;
    background-color: #34a853;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 41px;
    gap: 12px;
    padding-left: 20px;
    padding-right: 20px;
    width: 22%;
    height: 45px;
  }
}

.div-safety-log-filter-container {
  display: flex;
  justify-content: flex-end;
  .div-download-button-container {
    display: flex;
    align-items: center;
    .div-download-email-right {
      cursor: pointer;
      background-color: #34a853;
      color: #fff;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 21px;
      border-radius: 41px;
      gap: 12px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
