@import "../../../../../scss/variables";

.threeQuestionBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
  .threeQuestionList {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media print {
  .pdfOveride {
    &.InspectionContainer {
      .categoriesView {
        .commonInsOveride {
          .commoninfoWrap {
            .listViewBox {
              .listDetailBox {
                .listView {
                  span {
                    font-size: $font-size-10;
                  }
                }
              }
            }
          }
        }
      }
      .notWithThreeQuestion {
        .notWithThreeAnswer {
          margin-bottom: 5px;
          font-size: $font-size-10;
        }
      }
    }
  }
}

body {
  &.dark-mode {
    .InspectionContainer {
      .notWithThreeQuestion {
        .notWithThreeAnswer {
          color: $dark-primary-text-color;
        }
      }
    }
  }
}
