@import "../../../../scss/variables";

body {
  &.dark-mode {
    .questionBox {
      &.mainQuestion {
        background-color: $dark-secondary-background-color;
        color: $dark-primary-text-color;
        background: $dark-primary-background-color;
        border-radius: 0;
      }
      background-color: #333333;
    }
    .toolBoxOverRide {
      .CategoriesBox {
        background-color: $dark-tertiary-background-color;
      }
    }
    .QuestionBoxRes {
      .responseTypeBoxRes {
        background-color: $black-color;
        .responseType {
          color: $white-color;
        }
      }
    }
    .subCategoryTitle {
      background-color: $black-color;
      color: $white-color;
      .subCategoryTitleValue {
        .dragIcon {
          svg {
            fill: $dark-secondary-color;
          }
        }
      }
    }
    .questionItem {
      background-color: $black-color;
      .dragIcon {
        svg {
          fill: $dark-secondary-color;
        }
      }
    }
  }
}

.categoryTitle {
  background-color: $cerulean-color;
  padding: 10px;
  border-radius: 4px;
  color: $white-color;
  font-size: $font-size-16;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close {
    font-size: $font-size-18;
    font-weight: 500;
    cursor: pointer;
  }
  .categoryTitleValue {
    width: 80%;
    display: flex;
    align-items: center;
    .dragIcon {
      svg {
        fill: $white-color;
      }
    }
  }
  .skippedBox {
    .closeItem {
      color: $light-primary-background-color;
    }
    width: 20%;
    justify-content: space-between;
  }
}
.subCategoryTitle {
  background-color: $white-color;
  border: 1px solid $cerulean-color;
  padding: 10px;
  border-radius: 4px;
  color: $shadow-grey;
  font-size: $font-size-16;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close {
    font-size: $font-size-18;
    font-weight: 500;
    cursor: pointer;
  }

  .subCategoryTitleValue {
    width: 65%;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .dragIcon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg {
        fill: $light-primary-color;
      }
    }
  }
  .selectAll {
    width: 15%;
  }
  .skippedBox {
    width: 20%;
    justify-content: space-between;
  }
}

.questionBox {
  background-color: $fog-grey;
  padding: 5px 10px;
  border-radius: 4px;
  &.mainQuestion {
    background-color: $white-color;
  }
}
.questionItem {
  background-color: $white-color;
  border: 1px solid $gainsboro;
  padding: 10px 10px 5px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  .filterLableCheckbox{
    margin-left: 0px;
  }
  .dragIcon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;
    svg {
      fill: $light-primary-color;
    }
  }
}
.inspectionSet {
  &.table_style_ui {
    height: 100%;
    min-height: 400px;
    .overrideDataGrid {
      min-height: 400px;
      .MuiDataGrid-footerContainer {
        display: none;
      }
    }
  }
}
.optionListTitle {
  font-size: $font-size-16;
  font-weight: 600;
  color: $light-primary-text-color;
}

.optionListRes {
  list-style: auto;
  padding-left: 20px;
  li {
    font-size: $font-size-14;
    font-weight: 500;
    color: $light-primary-text-color;
    margin-bottom: 5px;
    span {
      font-size: $font-size-14;
      color: $light-primary-color;
      margin-left: 20px;
      cursor: pointer;
      font-weight: 500;
    }
  }
}
.QuestionBoxRes {
  .responseTypeBoxRes {
    background-color: $fog-grey;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 7px 20px;
    .responseType {
      font-size: $font-size-16;
      color: $shadow-grey;
      font-weight: 600;
      font-family: $font-lato;
    }
    .responseTypeRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      .responseTypeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        background-color: $white-color;
        min-width: 105px;
        padding: 5px 10px;
        border: 1px solid $sonic-silver;
        border-radius: 5px;
        font-size: $font-size-14;
        color: $cerulean-color;
        cursor: pointer;
        &.active {
          background-color: $cerulean-color;
          color: $white-color;
        }
      }
    }
  }
}
svg {
  &.deleteIcon {
    path {
      fill: $light-primary-color;
    }
  }
}

.customSelectMethod {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 8px;
  .check {
    display: flex;
    align-items: center;
    margin-right: 10px;
    .tickbox {
      position: relative;
    }
    .closeItem {
      position: relative;
      margin-left: 5px;
      margin-top: 0px;
    }
  }
}
.acknowledgementMessage {
  font-size: $font-size-14;
  font-weight: 500;
  color: $light-primary-text-color;
  line-height: 1.4;
}
