@import "../../../../scss/variables";

body {
  &.dark-mode {
    .couresList {
      .tabcustom.Mui-selected {
        color: $dark-primary-color;
      }
      .tabcustom {
        color: $dark-primary-text-color;
      }
      .rightFilter {
        .searchfilter {
          color: $dark-primary-text-color;
          background-color: $dark-form-all-input-color;
          .searchfilterInput {
            input {
              color: $dark-primary-text-color;
              background-color: $dark-form-all-input-color;
            }
          }
        }
      }
    }
  }
}

.couresList {
  margin-top: 50px;

  .rightFilter {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: flex-end;
    .div-download-email-right {
      cursor: pointer;
      background-color: #34a853;
      color: #fff;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 41px;
      gap: 10px;
      margin-right: 2px;
    }
    .searchfilter {
      border: 1px solid $fossil-grey;
      background-color: $white-color;
      border-radius: 15px;
      max-width: 190px;
      width: 100%;
      height: 100%;
      max-height: 44px;
      label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;

        span {
          display: flex;
          padding-left: 10px;
        }
        .css-1ocpf3a-MuiButtonBase-root-MuiTab-root {
          text-transform: capitalize;
        }
      }
      .searchfilterInput {
        height: 100%;
        input {
          border: none;
          height: 100%;
          width: 100%;
          border-radius: 15px;
          font-size: $font-size-16;
          color: $manatee-color;
          font-family: $font-lato;
          //font-weight: 600;
          &::placeholder {
            color: $manatee-color;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .filter_btn {
      max-width: 130px;
      width: 100%;
      height: 100%;
      max-height: 44px;
      margin-left: -8px;
      z-index: 10;
      margin-right: 14px;

      .btn {
        border: 1px solid #dde2e5;
        background-color: #fff;
        border-radius: 15px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $shadow-grey;
        font-size: $font-size-16;
        font-weight: 600;
        font-family: $font-lato;
        cursor: pointer;
        .filterIcons {
          color: $shadow-grey;
          margin-left: 10px;
          font-weight: 700;
          font-size: $font-size-26;
        }
      }
    }
  }
  .tabcustom {
    padding: 0px 0px 10px 0px;
    font-size: $font-size-16;
    font-family: $font-lato;
    color: $spanish-gray;
    margin-right: 40px;
    font-weight: 700;
    text-transform: capitalize;
    &.Mui-selected {
      color: $cerulean-color;
    }
    .totalV {
      background-color: $giants-orange;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      color: $white-color;
      font-family: $font-lato;
      font-size: $font-size-12;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
  }
  .MuiTabs-indicator {
    bottom: 24px;
    background-color: $cerulean-color;
  }
  &.noIn {
    .MuiTabs-indicator {
      bottom: 16px;
    }
  }

  .tabPanel {
    width: 100%;
    margin-top: 20px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-family: $font-lato;
    font-weight: 600;
    color: $text-black-color;
  }
}

.nameBox {
  display: flex;
  padding: 10px 0px;
  text-align: left;
  .nameImg {
    img {
      border-radius: 10px;
      width: 101px;
      height: 68px;
      object-fit: cover;
    }
  }
  .nameBoxpara {
    margin-left: 10px;
    white-space: break-spaces;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.news .nameBox .nameImg img {
  border-radius: 20px;
  width: 101px;
  height: 68px;
  object-fit: cover;
  padding: 13px;
  margin-top: 3px;
}
