@import "../../../../scss/variables";

body {
  &.dark-mode {
    .reportTitle {
      &.inspectionHeader {
        span {
          color: $dark-primary-text-color;
        }
      }
    }
    .btnStatusWrapper {
      .btnStatusIns {
        color: $dark-primary-text-color;
        background-color: $dark-secondary-background-color;
        &.active {
          background-color: $dark-primary-color;
        }
      }
    }
    .MuiAccordion-region {
      background-color: $dark-primary-background-color;
    }
    .swAddReport {
      .locationContainer {
        .locationListBox {
          .locationList {
            .locationTitile {
              span {
                color: $dark-primary-text-color;
              }
            }
          }
        }
      }
      .swAccordianChild {
        .swChildAccordian {
          background-color: $dark-secondary-background-color;
          .MuiAccordionSummary-expandIconWrapper {
            svg {
              color: $dark-primary-text-color;
            }
          }
          .subCatButton {
            .subCatButtonBox {
              .inspectPersantage {
                color: $dark-primary-text-color;
              }
            }
            .accoTitle {
              color: $dark-primary-text-color;
            }
          }
        }
      }
      .swAccordianParent {
        .accordianQuestionBox {
          background-color: $dark-secondary-background-color;
          .quesTitle {
            color: $dark-primary-text-color;
            background-color: $dark-secondary-background-color;
          }
        }
        .swAccordian {
          background-color: $dark-primary-color;
        }
      }
      .defaultMsg {
        color: $dark-primary-text-color;
      }
    }

    .viewReportIns {
      &.table_white {
        background-color: $white-color;
      }
      .title {
        color: $dark-primary-text-color;
      }
      table {
        .tableHead {
          background-color: $dark-primary-background-color;
          border: 1px solid $dark-primary-text-color;
          th {
            color: $dark-primary-text-color;
          }
        }
        .tableBody {
          .itemFondText {
            display: flex;

            span {
              color: $dark-primary-text-color;
            }
          }
          td {
            color: $dark-primary-text-color;
          }
        }
      }

      table,
      th,
      td {
        border-bottom: 1px solid $chinese-white;
        .statuss {
          div {
            &.closed {
              color: $green-color;
            }
            &.open {
              color: $nobg;
            }
          }
        }
      }
    }
  }
}

.approximate {
  .overideLables {
    .formLabel {
      font-size: $font-size-14;
    }
  }
}
.swAddReport {
  .alsoRoutine {
    display: block;
    position: relative;
    width: 100%;
    min-height: 30px;
    margin-top: 5px;
  }
  .locationContainer {
    .showUploadedImg {
      width: 100px;
      height: 50px;
      margin-top: 10px;
    }
    .addLocationCustomButton {
      .overideBtn {
        button {
          max-width: 165px;
          padding: 0 20px;
        }
      }
    }
    .locationListBox {
      .locationList {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;
        .addMedia {
          .multipleIconContainer {
            display: flex;
          }
        }
        .locationImg {
          img {
            width: 120px;
            height: 40px;
          }
          position: relative;
          .removeImg {
            border-radius: 100px;
            padding: 3px;
            color: $white-color;
            font-size: $font-size-18;
            font-family: $font-lato;
            font-weight: 600;
            background-color: $text-black-color;
            margin-left: 10px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -10px;
            right: -10px;
            cursor: pointer;
          }
        }
        .locationTitile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: 10px;
          border: 1px solid $boulder;
          padding: 10px;
          border-radius: 100px;
          span {
            color: $text-black-color;
            font-size: $font-size-14;
            font-family: $font-lato;
            font-weight: 600;
          }
          .removeImg {
            border-radius: 100px;
            padding: 3px;
            color: $white-color;
            font-size: $font-size-18;
            font-family: $font-lato;
            font-weight: 600;
            background-color: $text-black-color;
            margin-left: 10px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
    .lables {
      color: $shadow-grey;
      font-size: $font-size-16;
      font-family: $font-lato;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .locationInput {
      .locationInputBox {
        align-items: center;
        .overideBtn {
          margin-left: 10px;
        }
      }
      .mediaInputContainer {
        position: relative;
        .overideInputField {
          input {
            padding-right: 125px;
          }
        }
        .addMedia {
          position: absolute;
          right: 10px;
          top: 3px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          .multipleIconContainer {
            display: flex;
            margin-top: 10px;
          }
          .overideFormDropBox {
            .dropbox {
              padding: 0;
              border: none;
              height: auto;
              .multipleIconContainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
            }
          }
          span {
            color: $text-black-color;
            font-size: $font-size-14;
            font-family: $font-lato;
            font-weight: 400;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .defaultMsg {
    color: $text-black-color;
    font-size: $font-size-14;
    font-family: $font-lato;
    font-weight: 600;
  }
  .buttonEnd {
    justify-content: flex-end;
  }
  .signatureBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $cerulean-color;
    padding: 10px 24px 10px 16px;
    border-radius: 100px;
    max-width: fit-content;
    width: 200px;
  }
  .topHeaderProfile {
    .signatureImg {
      .borderSignatureImg {
        width: 205px;
        height: 130px;
      }
    }
  }

  .swAccordianParent {
    box-shadow: none;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: $gainsboro;
    .accordianQuestionBox {
      background-color: $white-color;
      margin-bottom: 0px;
      border-radius: 5px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-top: 15px;
      .quesTitle {
        color: $shadow-grey;
        font-weight: 600;
        font-size: $font-size-16;
        font-family: $font-lato;
        padding: 10px 20px 0px 20px;
      }
      .questionFreeText {
        padding: 0px 20px 20px 20px;
        margin-top: 10px;
      }
      .commonWrapper {
        padding: 0px 20px 20px 20px;
        margin-top: 10px;
      }
      .radioContainer {
        padding: 0px 20px 0px 20px;
        .Mui-disabled {
          svg {
            color: $gainsboro;
          }
          span.MuiTypography-root {
            color: $gainsboro;
          }
        }
      }
      .itemContainer {
        border-top: 1px solid $gainsboro;
        padding: 10px 20px;
        position: relative;
        .removeRepetBox {
          z-index: 9;
        }
      }
      .buttonConttainer {
        padding: 10px 20px;
      }
    }
    .swAccordian {
      flex-direction: row-reverse;
      background-color: $cerulean-color;
      border-radius: 4px;
      min-height: 40px;
      max-height: 50px;
      .cateTitleWInsRight {
        display: flex;
        align-items: center;
        .skipSubCat {
          background-color: $light-primary-color;
          border: 1px solid;
          border-radius: 10px;
          color: $white-color;
          cursor: pointer;
          font-family: $font-lato;
          font-size: $font-size-16;
          margin-right: 30px;
          outline: none;
          padding: 3px 15px;
        }
      }
      .cateTitleWIns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .catButtonBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .inspectPersantage {
          color: $white-color;
          font-size: $font-size-16;
          font-weight: 700;
          font-family: $font-lato;
        }
      }
      .accoTitle {
        color: $white-color;
        font-size: $font-size-18;
        font-weight: 700;
        font-family: $font-lato;
      }
      .MuiAccordionSummary-expandIconWrapper {
        svg {
          color: $white-color;
          margin-right: 0px;
          font-size: $font-size-26;
        }
      }
    }
  }
  .swAccordianChild {
    box-shadow: none;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: $gainsboro;

    .swChildAccordian {
      flex-direction: row-reverse;
      border: 1px solid $cerulean-color;
      background-color: $white-color;
      border-radius: 4px;
      min-height: 40px;
      max-height: 50px;
      .subCatButton {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .accoTitle {
          width: 35%;
        }
        .subCatButtonBox {
          // width: 65%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .allacceptbleBtn {
            background-color: $green-color;
            color: $white-color;
            font-size: $font-size-14;
            font-weight: 600;
            font-family: $font-lato;
            border-radius: 5px;
            padding: 10px 24px 10px 24px;
          }
          .allNotapplicableBtn {
            background-color: $inprogress;
            color: $white-color;
            font-size: $font-size-14;
            font-weight: 600;
            font-family: $font-lato;
            border-radius: 5px;
            padding: 10px 24px 10px 24px;
          }
          .inspectPersantage {
            color: $shadow-grey;
            font-size: $font-size-16;
            font-weight: 700;
            font-family: $font-lato;
          }
        }
      }
      .accoTitle {
        color: $shadow-grey;
        font-size: $font-size-18;
        font-weight: 700;
        font-family: $font-lato;
      }
      .MuiAccordionSummary-expandIconWrapper {
        svg {
          color: $shadow-grey;
          margin-right: 0px;
          font-size: $font-size-26;
        }
      }
    }
  }
}

.btnStatusWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  .btnStatusIns {
    color: $text-black-color;
    font-size: $font-size-14;
    font-weight: 600;
    font-family: $font-lato;
    border: 1px solid $gainsboro;
    padding: 10px 24px 10px 24px;
    cursor: pointer;
    &.active {
      background-color: $cerulean-color;
      color: $white-color;
    }
  }
}

.posChange {
  position: absolute;
  top: 7px;
  left: 37%;
  .subCatButtonBox {
    display: flex;
    align-items: center;
    .allacceptbleBtn {
      background-color: $green-color;
      color: $white-color;
      font-size: $font-size-14;
      font-weight: 600;
      font-family: $font-lato;
      border-radius: 5px;
      padding: 10px 24px 10px 24px;
      cursor: pointer;
      margin-right: 10px;
    }
    .allNotapplicableBtn {
      background-color: $shadow-grey;
      color: $white-color;
      font-size: $font-size-14;
      font-weight: 600;
      font-family: $font-lato;
      border-radius: 5px;
      padding: 10px 24px 10px 24px;
      cursor: pointer;
    }
  }
}
.listCommonForm {
  list-style: decimal;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  li {
    padding-right: 40px;
    margin-bottom: 10px;
    &::marker {
      font-weight: bold;
    }
  }
}
img {
  &.imgViewReport {
    width: 95px;
    height: 54px;
  }
}

.openInfoContainer {
  margin-right: 10px;
  .openInfoInfo {
    img {
      width: 20px;
      height: 20px;
    }
    &:hover {
      .openInfoMsg {
        display: block;
        position: absolute;
        background-color: $white-color;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 3px 3px 4px $shadow-grey;
        right: 0;
        top: 32px;
        font-size: $font-size-14;
        color: $text-black-color;
        font-weight: 600;
        max-width: 200px;
        text-align: center;
        z-index: 9;
      }
    }
    .openInfoMsg {
      display: none;
    }
  }
}
.viewReportIns {
  width: 100%;
  &.table_white {
    background-color: $white-color;
  }
  .title {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: $font-size-16;
    font-weight: 700;
    font-family: $font-lato;
    color: $cerulean-color;
  }
  table {
    width: 100%;
    .tableHead {
      background-color: $Bright-Gray;
      th {
        font-size: $font-size-16;
        font-weight: 700;
        font-family: $font-lato;
        color: $text-black-color;
        // &:nth-child(1) {
        //   width: 100px;
        // }
        // &:nth-child(2) {
        //   width: 250px;
        // }
        // &:nth-child(3) {
        //   width: 100px;
        // }
        // &:nth-child(4) {
        //   width: 90px;
        // }
        // &:nth-child(5) {
        //   width: 90px;
        // }
        // &:nth-child(6) {
        //   width: 90px;
        // }
      }
    }
    .tableBody {
      .itemFondText {
        display: flex;

        span {
          font-size: $font-size-16;
          font-weight: 400;
          font-family: $font-lato;
          color: $text-black-color;
          div[style] {
            width: auto !important;
            position: relative !important;
          }
          &:first-child {
            font-weight: 500;
          }
          &:last-child {
            margin-left: 5px;
          }
        }
      }
      td {
        font-size: $font-size-16;
        font-weight: 400;
        font-family: $font-lato;
        color: $text-black-color;
        vertical-align: top;
        min-width: 100px;
        // &:nth-child(1) {
        //   width: 100px;
        // }
        // &:nth-child(2) {
        //   width: 250px;
        // }
        // &:nth-child(3) {
        //   width: 100px;
        // }
        // &:nth-child(4) {
        //   width: 90px;
        // }
        // &:nth-child(5) {
        //   width: 90px;
        // }
        // &:nth-child(6) {
        //   width: 90px;
        // }
      }
    }
  }

  table,
  th,
  td {
    border-bottom: 1px solid $chinese-white;
    border-collapse: collapse;
    padding: 10px;
    .statuss {
      div {
        font-size: $font-size-16;
        font-weight: 700;
        font-family: $font-lato;
        &.closed {
          color: $green-color;
        }
        &.open {
          color: $nobg;
        }
      }
    }
  }
}

.addProof {
  .check {
    display: flex;
    margin-top: 20px;
    .closeItem {
      position: relative;
    }
  }
  .addProofTitle {
    font-size: $font-size-24;
    font-weight: 700;
    font-family: $font-lato;
    color: $text-black-color;
    margin-bottom: 30px;
  }
  .buttonEnd {
    .overideBtn {
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
}
.signatureProof {
  .signatureBtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.viewProof {
  .viewProofImages {
    .viewProofImage {
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    width: 100%;
  }
}

.listPermitteContainer {
  display: flex;
  justify-content: left;
  &.listPermiteSpace {
    .formLabel {
      margin-right: 15px;
    }
  }
}
.reportTitle {
  &.inspectionHeader {
    font-size: $font-size-20;
    span {
      color: $black-color;
      font-size: $font-size-18;
      font-weight: 500;
    }
  }
}

.cateTitleWInsRight {
  display: flex;
  align-items: center;
  .skipCat {
    background-color: $light-primary-color;
    border: 1px solid;
    border-radius: 10px;
    color: $white-color;
    cursor: pointer;
    font-family: $font-lato;
    font-size: $font-size-16;
    margin-right: 30px;
    outline: none;
    padding: 3px 15px;
  }
  .skipSubCat {
    background-color: $light-primary-color;
    border: 1px solid;
    border-radius: 10px;
    color: $white-color;
    cursor: pointer;
    font-family: $font-lato;
    font-size: $font-size-16;
    margin-right: 30px;
    outline: none;
    padding: 3px 15px;
  }
}
