@import "../../../scss//variables";

.dynamic-table {
  margin: 20px 0;
  width: 100%;

  h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: $black-color;
    font-family: $font-lato;
    font-size: $font-size-16;
    font-weight: 700;
    text-transform: capitalize;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        background-color: $Bright-Gray;

        th {
          border: 1px solid $gainsboro;
          padding: 10px;
          text-align: left;
          font-size: 14px;
          font-weight: 600;
          color: $text-black-color;
          &.phaseProjectWidth {
            width: 60%;
          }
        }
      }
    }

    tbody {
      background-color: $white-color;
      tr {
        td {
          border: 1px solid $gainsboro;
          padding: 10px;
          text-align: left;
          font-size: 14px;
          color: $text-black-color;
        }
      }
    }
  }
}
@media print {
  .pdfOveride {
    .dynamic-table {
      margin-top: 10px;
      h3 {
        font-size: $font-size-12;
      }
      table {
        thead {
          tr {
            th {
              font-size: $font-size-10;
              padding: 3px;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: $font-size-10;
              padding: 3px;
              .checkboxShow {
                img {
                  width: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

body {
  &.dark-mode {
    .dynamic-table {
      h3 {
        color: $dark-primary-text-color;
      }
      table {
        thead {
          tr {
            background-color: $dark-grid-hover-color;
            th{
              color: $dark-primary-text-color;
            }
          }
        }
        tbody {
          background-color: $dark-secondary-background-color;
          tr{
            td{
              color: $dark-primary-text-color;
              .checkboxShow{
                svg{
                  path{
                    fill: $dark-primary-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
